
<script>

import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  name: 'TopChart',
  props:["toggleChart","dataChart","labels","maxCount","stepSize"],
  
  data () {
    return {
      x_fontSize: 15,
      y_fontSize: 15,
      data: {

        datasets: [
          {
            data: [0,0,0,0,0],
            borderColor: "#4ECDC4",
            lineTension:0,
            fill: "start",
            backgroundColor: '#E5F6FA' 
          },
        ]
      },
      options: {
        scales: {
            xAxes:[{
              gridLines: {
                display: false,
              },
              ticks: {
              autoSkip: false,
              fontSize:this.x_fontSize,
            }
            }
            ],
            yAxes: [{
              ticks: {
                Max: this.max_count,
                Min: 0,
                stepSize:1,
                fontSize:this.y_fontSize,
              },
              position: "right", //第2軸(右側軸)になる
            }]
          },
        legend: {
          display: false
        }
      }
      
    }
  },

  watch : {
    //グラフの再描写
    toggleChart: function(){
      this.data.labels = this.labels;
      this.data.datasets[0].data = this.dataChart;
      this.options.scales.yAxes[0].ticks.max = this.maxCount;
      this.options.scales.yAxes[0].ticks.min = 0; 
      this.max_count = this.maxCount;
      this.options.scales.yAxes[0].ticks.stepSize = this.stepSize;
      this.renderChart(this.data, this.options)
    }
  }
}

</script>

